/* HORIZONTAL TABS */
.tabs {

}

.tab {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}
@media only screen and (min-width: 900px) {
  .tab {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    border-right: none;
  }
}

.tab:last-child {
  border-bottom: 1px solid #000;
}
@media only screen and (min-width: 900px) {
  .tab:last-child {
    border-right: 1px solid #000;
  }
}

.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
}
@media only screen and (min-width: 900px) {
  .tab-header {
    flex-direction: column;
    justify-content: space-between;
  }
}

.tab-header p {
  color: #0023EC;
  pointer-events: none;
}

.tab-header h3 {
  pointer-events: none;
  margin: 0;
  padding: 0;
  color: #0023EC;
}
@media only screen and (min-width: 900px) {
  .tab-header h3 {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }
}

.tab-content {
  overflow: hidden;
  padding: 0;
  max-width: 0;
}

.tab-content-container {
  display: none;
}

.tab-content.is-active {
  padding: 16px;
  max-width: 1200px;
  overflow: hidden;
}

.tab-content.is-active .tab-content-container {
  display: flex;
  flex-direction: column;
}
